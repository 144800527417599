import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDRXH9dlwV3SHbDN9UEPx2oUWB6brgHT2w",
  authDomain: "weddinginvitation-1f36b.firebaseapp.com",
  databaseURL:
    "https://weddinginvitation-1f36b-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "weddinginvitation-1f36b",
  storageBucket: "weddinginvitation-1f36b.appspot.com",
  messagingSenderId: "746288310044",
  appId: "1:746288310044:web:f550b66c1d87ab8533fcd9",
  measurementId: "G-0TMDP244DM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
