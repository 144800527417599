import { db } from "./firebase-config";

import {
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore";

const greetingCollectionRef = collection(db, "greetingCard");

class GreetingDataService {
  addGreetings = (newGreeting) => {
    return addDoc(greetingCollectionRef, newGreeting);
  };

  getAllGreetings = () => {
    return getDocs(greetingCollectionRef);
  };
}

export default new GreetingDataService();
